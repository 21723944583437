import {
  CameraAltTwoTone,
  CodeTwoTone,
  ColorLensTwoTone,
  ConfirmationNumberTwoTone,
  DescriptionTwoTone,
  DesignServicesTwoTone,
  DeveloperModeTwoTone,
  DevicesOtherTwoTone,
  EmojiObjectsTwoTone,
  FormatAlignCenterTwoTone,
  FormatColorFillTwoTone,
  FormatShapesTwoTone,
  GestureTwoTone,
  HomeWorkTwoTone,
  InsertChartTwoTone,
  LayersTwoTone,
  LocalOfferTwoTone,
  LyricsTwoTone,
  MailTwoTone,
  MicTwoTone,
  PageviewTwoTone,
  PermMediaTwoTone,
  PrintTwoTone,
  PsychologyTwoTone,
  RememberMeTwoTone,
  ScreenShareTwoTone,
  ShoppingBagTwoTone,
  ShoppingBasketTwoTone,
  SmartToyTwoTone,
  StorefrontTwoTone,
  TerminalTwoTone,
  ThumbUpTwoTone,
  TrendingUpTwoTone,
  VideocamTwoTone,
  VideogameAssetTwoTone,
  WebTwoTone,
} from '@mui/icons-material';
import { TFunction } from '@sortlist-frontend/translation/ssr';

export type expertisesIdNumbers =
  | 58
  | 60
  | 61
  | 62
  | 63
  | 65
  | 66
  | 67
  | 68
  | 69
  | 70
  | 72
  | 74
  | 75
  | 77
  | 79
  | 83
  | 84
  | 85
  | 88
  | 89
  | 91
  | 93
  | 94
  | 95
  | 96
  | 97
  | 98
  | 99
  | 100
  | 101
  | 102
  | 103
  | 104
  | 105;
export type expertisesIdStrings =
  | '3d-design'
  | 'advertising'
  | 'animation'
  | 'app-development'
  | 'artificial-intelligence'
  | 'audio-production'
  | 'branding'
  | 'content-marketing'
  | 'copywriting'
  | 'data-consulting'
  | 'design'
  | 'digital-marketing'
  | 'ecommerce'
  | 'email-marketing'
  | 'event'
  | 'gaming'
  | 'innovation'
  | 'marketing'
  | 'media-planning'
  | 'online-ad'
  | 'photography'
  | 'print'
  | 'product-management'
  | 'public-relations'
  | 'seo'
  | 'social-media'
  | 'software-development'
  | 'ux-agency'
  | 'video'
  | 'web-analytics'
  | 'web-design'
  | 'web-development';
export type ExpertiseIdType = expertisesIdNumbers | expertisesIdStrings;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getExpertiseDetails = (t: TFunction, expertise: ExpertiseIdType, size?: number) => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (expertise) {
    case 58 || 'digital-marketing':
      return {
        description: t('common:expertises.digitalMarketing.description'),
        icon: <ScreenShareTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 60 || 'branding':
      return {
        description: t('common:expertises.branding.description'),
        icon: <FormatShapesTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 61 || 'ux-agency':
      return {
        description: t('common:expertises.uxAgency.description'),
        icon: <DevicesOtherTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 62 || 'content-marketing':
      return {
        description: t('common:expertises.contentMarketing.description'),
        icon: <DescriptionTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 63 || 'ecommerce':
      return {
        description: t('common:expertises.ecommerce.description'),
        icon: <ShoppingBasketTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 65 || 'web-development':
      return {
        description: t('common:expertises.webDevelopment.description'),
        icon: <CodeTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 66 || 'web-design':
      return {
        description: t('common:expertises.webDesign.description'),
        icon: <WebTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 67 || 'design':
      return {
        description: t('common:expertises.design.description'),
        icon: <FormatColorFillTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 68 || 'animation':
      return {
        description: t('common:expertises.animation.description'),
        icon: <GestureTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 69 || 'video':
      return {
        description: t('common:expertises.video.description'),
        icon: <VideocamTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 70 || 'app-development':
      return {
        description: t('common:expertises.appDevelopment.description'),
        icon: <DeveloperModeTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 72 || 'copywriting':
      return {
        description: t('common:expertises.copywriting.description'),
        icon: <FormatAlignCenterTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 74 || 'social-media':
      return {
        description: t('common:expertises.socialMedia.description'),
        icon: <ThumbUpTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 75 || 'gaming':
      return {
        description: t('common:expertises.gaming.description'),
        icon: <VideogameAssetTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 77 || 'event':
      return {
        description: t('common:expertises.event.description'),
        icon: <ConfirmationNumberTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 79 || 'seo':
      return {
        description: t('common:expertises.seo.description'),
        icon: <PageviewTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 83 || 'media-planning':
      return {
        description: t('common:expertises.mediaPlanning.description'),
        icon: <PermMediaTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 84 || 'advertising':
      return {
        description: t('common:expertises.advertising.description'),
        icon: <LocalOfferTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 85 || 'email-marketing':
      return {
        description: t('common:expertises.emailMarketing.description'),
        icon: <MailTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 88 || 'online-ad':
      return {
        description: t('common:expertises.onlineAd.description'),
        icon: <StorefrontTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 89 || ' 3d-design':
      return {
        description: t('common:expertises.3dDesign.description'),
        icon: <LayersTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 91 || 'public-relations':
      return {
        description: t('common:expertises.publicRelations.description'),
        icon: <MicTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 93 || 'photography':
      return {
        description: t('common:expertises.photography.description'),
        icon: <CameraAltTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 94 || 'web-analytics':
      return {
        description: t('common:expertises.webAnalytics.description'),
        icon: <TrendingUpTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 95 || 'innovation':
      return {
        description: t('common:expertises.innovation.description'),
        icon: <EmojiObjectsTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 96 || 'data-consulting':
      return {
        description: t('common:expertises.dataConsulting.description'),
        icon: <InsertChartTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 97 || 'artificial-intelligence':
      return {
        description: t('common:expertises.artificialIntelligence.description'),
        icon: <SmartToyTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 98 || 'audio-production':
      return {
        description: t('common:expertises.audioProduction.description'),
        icon: <LyricsTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 99 || 'graphic-identity':
      return {
        description: t('common:expertises.graphicIdentity.description'),
        icon: <ColorLensTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 100 || 'influencer-marketing':
      return {
        description: t('common:expertises.influencerMarketing.description'),
        icon: <RememberMeTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 101 || 'marketing':
      return {
        description: t('common:expertises.marketing.description'),
        icon: <ShoppingBagTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 102 || 'outdoor-advertising':
      return {
        description: t('common:expertises.outdoorAdvertising.description'),
        icon: <HomeWorkTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 103 || 'print':
      return {
        description: t('common:expertises.print.description'),
        icon: <PrintTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 104 || 'product-management':
      return {
        description: t('common:expertises.productManagement.description'),
        icon: <PsychologyTwoTone style={{ fontSize: size || 16 }} />,
      };
    case 105 || 'software-development':
      return {
        description: t('common:expertises.softwareDevelopment.description'),
        icon: <TerminalTwoTone style={{ fontSize: size || 16 }} />,
      };
    default:
      return {
        icon: <DesignServicesTwoTone style={{ fontSize: size || 16 }} />,
      };
  }
};
