import { HttpExceptionFactory } from '@sortlist-frontend/utils';
import axios from 'axios';

import { getFilteredQueryParams } from '_core/api/cqs/get-filtered-query-params';

type SearchParams = Record<string, string | number | boolean | null>;

/**
 * Yep it might be difficult to require generics, so let's disable
 * inference from given params
 */
type NoInfer<T> = [T][T extends unknown ? 0 : never];

export const cqsQueryFetch = async <
  TCqsQueryPayload extends Record<string, unknown> = { error: 'No type parameter was supplied' },
  TSearchParams extends SearchParams = { error: 'No type parameter was supplied' },
>(
  url: string,
  searchParams: NoInfer<TSearchParams>,
  options: {
    cacheVersion: string;
    disableCache: boolean;
  },
): Promise<NoInfer<TCqsQueryPayload>> => {
  const { disableCache, cacheVersion } = options;
  return axios
    .get(url, {
      timeout: 5000,
      headers: {
        ...(disableCache
          ? {
              'Cache-control': 'no-cache,no-store',
            }
          : {}),
      },
      params: {
        ...getFilteredQueryParams(searchParams),
        _v: cacheVersion,
        ...(disableCache
          ? {
              disableCache: 'true',
            }
          : {}),
      },
    })
    .then((resp) => {
      if (resp.status === 200) {
        return resp?.data?.data;
      }
      throw HttpExceptionFactory.fromStatus(resp.status, resp.statusText);
    });
};
