import { useTracker } from '_core/hooks/use-tracker';

export const trackWork = (agencySlug: string, work: { name?: string | undefined; slug: string | undefined }) => {
  const { track } = useTracker();
  track(`workInspected`, {
    workSlug: work?.slug,
    page: 'profile',
    item: work.name,
    agencySlug: agencySlug,
    url: window.location.href,
  });
  track(`workImpression`, {
    workSlug: work?.slug,
    agencySlug: agencySlug,
    type: 'profile-modal',
  });
};
