import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';

export type MenuItemProps = {
  color?: string;
  backgroundcolor?: string;
};

export const MenuItem = styled(MuiMenuItem)`
  ${(props: MenuItemProps) =>
    !!props.color || !!props.backgroundcolor
      ? `&.MuiMenuItem-root {
              ${props.color ? `color: ${props.color}` : ''};
              ${props.backgroundcolor ? `background-color: ${props.backgroundcolor}` : ''};
        }`
      : ''}
`;
