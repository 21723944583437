import { getJsonSafeString } from '@sortlist-frontend/utils';
import { ProfilePageJsonLd } from 'next-seo';

type Props = {
  agencyName: string;
  agencyUpdatedAt: string;
};

export const ProfilePage: React.FC<Props> = (props) => {
  const { agencyName, agencyUpdatedAt } = props;

  return (
    <ProfilePageJsonLd breadcrumb={`Sortlist > ${getJsonSafeString(agencyName)}`} lastReviewed={agencyUpdatedAt} />
  );
};
