/* eslint-disable sonarjs/cognitive-complexity */
import {
  CardMembershipTwoTone,
  CollectionsTwoTone,
  EmojiEventsTwoTone,
  EuroTwoTone,
  FlagTwoTone,
  LaptopTwoTone,
  PeopleTwoTone,
  RemoveRedEyeTwoTone,
  WorkTwoTone,
} from '@mui/icons-material';
import { Rating } from '@sortlist-frontend/design-system';
import { getFormattedRating } from '@sortlist-frontend/shared-components';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { stringToSafeInteger } from '@sortlist-frontend/utils';
import clsx from 'clsx';

import { ClampLines } from '_components/common/ClampLines';
import { CertifiedFlag } from '_components/common/flags/CertifiedFlag';
import { VerifiedFlag } from '_components/common/flags/VerifiedFlag';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { ComparedAgency } from '_components/Comparator/utils';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionsDetails } from '_features/agency/profile/components';
import { SectionBox } from '_features/agency/profile/components/section-box';
import { AgencyStats, Section } from '_features/agency/profile/utils';

import * as S from './about.styles';
import { CallToActions } from './components/call-to-actions';
import { Languages } from './components/languages.component';

type Props = {
  agencyStats: AgencyStats;
  children?: never;
  className?: string;
  handleActivePanel: (value: Section | undefined) => void;
  comparedAgencies: ComparedAgency[];
  setComparedAgencies: (agencies: ComparedAgency[]) => void;
};

const getYearFromIso8601 = (date: string): number | null => {
  return stringToSafeInteger(date.slice(0, 4));
};

const getSection = (value: string) => {
  return SectionsDetails.find((section) => section.id === value);
};

export const AboutPanel: React.FC<Props> = ({
  className,
  handleActivePanel,
  agencyStats,
  comparedAgencies,
  setComparedAgencies,
}) => {
  const { track } = useTracker();
  const { locale, pageData, getUrl } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);

  const {
    name,
    locality,
    country,
    address,
    tagline,
    verified,
    description,
    languages,
    profile_views_count,
    collaborations_count,
    remote,
    membership_at,
    founded_at,
    average_hourly_rate,
    plan,
  } = agency || {};
  const { teamMembersCount, worksCount, reviewsCount, reviewsScore, awardsCount } = agencyStats;

  const profileViewsCount = profile_views_count || 0;
  const collaborationsCount = collaborations_count || 0;

  const trackClaim = () => {
    track('agencyOnboardingStarted', { agencySlug, claim: true });
  };

  return (
    <SectionBox id="about" className={clsx(className, 'relative')}>
      <div className="pb-64 pt-xs-64 pt-gt-xs-16 px-gt-sm-32 px-0 layout-column layout-gt-sm-row">
        <div className="flex-none flex-gt-sm-70 layout-column p-16">
          <div className="p-8">
            <div className="m-8 layout-column layout-align-start-start">
              <div className="layout-row layout-align-center-center">
                <h1 className="bold h1 text-break-word">{name}</h1>
                <S.FlagsWrapper className="layout-row ml-16">
                  {verified ? <VerifiedFlag t={t} /> : null}
                  {plan !== 'free' ? <CertifiedFlag className={clsx(verified && 'ml-8')} t={t} /> : null}
                </S.FlagsWrapper>
              </div>
              <span className="p">{locality && country ? `${locality}, ${country}` : address}</span>
            </div>
          </div>

          <div className="p-16 text-break-word">
            {tagline && <div className="bold h4 mb-16" dangerouslySetInnerHTML={{ __html: tagline }}></div>}

            <ClampLines text={description as string} className="lh-2" sanitizeCollapsedText={true} />
          </div>

          <div className="layout-row layout-wrap p-8">
            {teamMembersCount > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role={'button'}
                tabIndex={0}
                onKeyDown={() => handleActivePanel(getSection('team'))}
                onClick={() => handleActivePanel(getSection('team'))}
                className="text-secondary-900 cursor-pointer flex-50 flex-xs-100 layout-row layout-align-start-center">
                <PeopleTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="small m-8">
                  <Trans i18nKey={'agency:profile.about.details.team'} count={teamMembersCount}>
                    <b>{{ count: teamMembersCount }}</b> people in their team
                  </Trans>
                </span>
              </a>
            )}

            {languages && languages?.length > 0 && <Languages />}

            {worksCount > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                tabIndex={0}
                role={'button'}
                onKeyDown={() => handleActivePanel(getSection('portfolio'))}
                onClick={() => handleActivePanel(getSection('portfolio'))}
                className="text-secondary-900 cursor-pointer flex-50 flex-xs-100 layout-row layout-align-start-center">
                <CollectionsTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.works'} count={worksCount}>
                    <b>
                      {{ count: worksCount }} works
                    </b>
                    in their portfolio
                  </Trans>
                </span>
              </a>
            )}
            {profileViewsCount > 0 && (
              <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
                <RemoveRedEyeTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.views'} count={profile_views_count}>
                    <b>
                      {{ count: profile_views_count }} people seen
                    </b>
                    this profile
                  </Trans>
                </span>
              </div>
            )}
            {collaborationsCount > 0 && (
              <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
                <WorkTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.collaborations'} count={collaborations_count}>
                    <b>
                      {{ count: collaborations_count }} collaborations
                    </b>
                    started on Sortlist
                  </Trans>
                </span>
              </div>
            )}
            {remote && (
              <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
                <LaptopTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.remote'}>
                    <b>Works remotely</b> across the globe
                  </Trans>
                </span>
              </div>
            )}
            {membership_at && getYearFromIso8601(membership_at)?.toString().length === 4 && (
              <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
                <CardMembershipTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.membership'}>
                    Sortlist
                    <b>
                      member since {{ year: getYearFromIso8601(membership_at) }}
                    </b>
                  </Trans>
                </span>
              </div>
            )}
            {founded_at && getYearFromIso8601(founded_at)?.toString().length === 4 && (
              <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
                <FlagTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.foundation'}>
                    <b>
                      Founded in {{ year: getYearFromIso8601(founded_at) }}
                    </b>
                  </Trans>
                </span>
              </div>
            )}
            {average_hourly_rate && average_hourly_rate > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onKeyDown={() => handleActivePanel(getSection('reviews'))}
                role={'button'}
                tabIndex={0}
                onClick={() => handleActivePanel(getSection('reviews'))}
                className="text-secondary-900 cursor-pointer flex-50 flex-xs-100 layout-row layout-align-start-center">
                <EuroTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.averageHourRate'}>
                    Average rate of
                    <b>
                      {{ rate: average_hourly_rate }}€/hour
                    </b>
                  </Trans>
                </span>
              </a>
            )}
            {awardsCount > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role={'button'}
                tabIndex={0}
                onKeyDown={() => handleActivePanel(getSection('awards'))}
                onClick={() => handleActivePanel(getSection('awards'))}
                className="text-secondary-900 cursor-pointer flex-50 flex-xs-100 layout-row layout-align-start-center">
                <EmojiEventsTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
                <span className="m-8 small text-break-word">
                  <Trans i18nKey={'agency:profile.about.details.awards'} count={awardsCount}>
                    <b>
                      {{ count: awardsCount }} awards
                    </b>
                    conferred
                  </Trans>
                </span>
              </a>
            )}
          </div>
        </div>
        <div className="flex-none flex-gt-sm-30 p-32">
          <S.AboutSectionStickyBox className="layout-column layout-align-start-stretch">
            <div className="border border-secondary-300 rounded-md mb-8">
              <div className="layout-column py-32 px-16 layout-align-center-center text-center">
                {reviewsCount > 0 ? (
                  <span className="mb-8">
                    <span className="h1 bold">{getFormattedRating(reviewsScore)}</span>
                    <span className="h5">/5</span>
                  </span>
                ) : (
                  <div className="pb-16 px-16 layout-column text-center layout-align-center-center small">
                    <span className="mb-4">{t('agency:profile.about.details.noReview')}</span>
                    <Obfuscate
                      className="underline text-primary-500"
                      href={`/agency/${agencySlug}/review`}
                      obfuscate={true}>
                      {t('agency:profile.about.details.reviewFirstCta')}
                    </Obfuscate>
                  </div>
                )}
                <Rating count={5} rate={reviewsCount > 0 ? getFormattedRating(reviewsScore) : 0} direction="column">
                  {reviewsCount > 0 ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      className="text-secondary-900 underline mt-4 cursor-pointer"
                      onClick={() => handleActivePanel(getSection('reviews'))}
                      onKeyDown={() => handleActivePanel(getSection('reviews'))}
                      tabIndex={0}
                      role={'button'}>
                      <Trans i18nKey={'agency:profile.about.details.reviewsCount'} count={reviewsCount}>
                        ({{ count: reviewsCount }} reviews)
                      </Trans>
                    </a>
                  ) : null}
                </Rating>
              </div>
            </div>

            <CallToActions
              agencySlug={agencySlug}
              agency={agency}
              comparedAgencies={comparedAgencies}
              setComparedAgencies={setComparedAgencies}
              certified={Boolean(membership_at)}
            />

            {!verified ? (
              <div className="p-16 small">
                <span className="mb-8">{t('agency:profile.about.notManaged')}</span>{' '}
                <Obfuscate
                  className="text-primary-500 underline"
                  href={getUrl('agency.claim.confirm', { slugOrUuid: agencySlug })}
                  onClick={trackClaim}
                  obfuscate={true}>
                  {t('agency:profile.about.claimCta')}
                </Obfuscate>
              </div>
            ) : null}
          </S.AboutSectionStickyBox>
        </div>
      </div>
    </SectionBox>
  );
};
