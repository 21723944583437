import { Rating } from '@sortlist-frontend/design-system';
import { Trans } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { SectionsDetails } from '_features/agency/profile/components';
import * as S from '_features/agency/profile/sections/services/services.styles';
import { Section } from '_features/agency/profile/utils';

type Props = {
  serviceName: string;
  reviews: GetAgencyServices['services'][0]['reviews']['data'];
  className?: string;
  agencySlug?: string;
  handleActivePanel: (value: Section | undefined) => void;
};

export const ServiceReviews: FC<Props> = (props) => {
  const { className, serviceName, reviews, handleActivePanel, agencySlug } = { ...props };
  const maxNumberOfCardsDisplayed = 4;

  return (
    <section className={`${className} p-16 layout-column`}>
      <header className="p-8">
        <Trans i18nKey={'agency:profile.services.reviewsTitle'} count={reviews.length}>
          <b>Reviews</b> in {{ serviceName: serviceName }} ({{ count: reviews.length }})
        </Trans>
      </header>
      <div className="flex layout-row p-8">
        <div className="flex layout-row layout-wrap">
          {reviews.slice(0, maxNumberOfCardsDisplayed).map((review, index) => (
            <div
              key={`${review.user_full_name}-${index}`}
              className="pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20 layout-row">
              <div className="shadow-2 p-8 flex rounded-sm bg-neutral-100 layout-column">
                <span className="small bold mb-4 text-truncate">{review.user_full_name}</span>
                <div className="layout-row">
                  <Rating rate={review.rating * 5} size={14} />
                </div>
              </div>
            </div>
          ))}
          {reviews.length > maxNumberOfCardsDisplayed && (
            <div className="pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20">
              <S.ItemExpand
                size="lg"
                buttonStyle="secondary"
                className="layout-fill bg-secondary-200 text-secondary-500"
                label={`+${reviews.length - maxNumberOfCardsDisplayed}`}
                onClick={() => handleActivePanel(SectionsDetails?.find((section) => section.id === 'reviews'))}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
