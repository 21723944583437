import { ArrowForwardRounded, EmojiEventsTwoTone } from '@mui/icons-material';
import { Button, Divider } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useState } from 'react';

import { GetAgencyAwards } from '_backend/queries/get-agency-awards/get-agency-awards.types';
import { WorkDetailModal } from '_components/common/WorkDetailModal';
import { trackWork } from '_components/common/WorkDetailModal/tracker';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';

type Props = {
  award: GetAgencyAwards['awards'][0];
  agencyName: string;
  agencySlug: string;
  agencyLogoUrl: string;
  scrollCtn?: Element | null;
  disableCache?: boolean;
  className?: string;
};

export const Award: FC<Props> = (props) => {
  const { award, agencyName, agencySlug, agencyLogoUrl, disableCache, className } = props;
  const { work } = award;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale } = usePublicAppContext();
  const [open, setOpen] = useState(false);
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);

  const openWork = () => {
    trackWork(agencySlug, { slug: work?.slug });
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={clsx(className, 'p-8 layout-column')}>
        <div className="rounded-md shadow-2 bg-neutral-100 flex layout-column border border-content-900 layout-align-space-between">
          <div className="layout-column layout-align-start-start p-16">
            <div className="rounded bg-secondary-200 layout-column layout-align-center-center text-center p-16 mb-32">
              <EmojiEventsTwoTone className="text-secondary-700" style={{ fontSize: 46 }} />
            </div>
            <span className="bold mb-8">{award.name}</span>
            <span className="small text-secondary-900 mb-16">{award.won_at}</span>
            <span className="small text-secondary-500 mb-8">{award.category}</span>
          </div>
          {award.work && (
            <div>
              <Divider />
              <Button
                onClick={openWork}
                buttonStyle="secondary"
                buttonVariant="default"
                size="md"
                className="text-left small"
                iconRight={<ArrowForwardRounded style={{ fontSize: 16 }} />}
                label={t('agency:profile.awards.link')}
                id={`open-work-btn-${work?.slug}`}
              />
            </div>
          )}
        </div>
      </div>

      {work && (
        <WorkDetailModal
          agencyName={agencyName}
          agencySlug={agencySlug}
          agencyBrandingPlan={agency?.branding_plan || ''}
          agencyLogoUrl={agencyLogoUrl}
          open={open}
          onClose={closeDialog}
          workSlug={work.slug}
          workSlugs={undefined}
          works={[]}
          index={0}
          disableCache={disableCache}
        />
      )}
    </>
  );
};
