import { ImageSourceHelper } from '@sortlist-frontend/media';

import { MediaAttachment } from '_backend/queries/shared';

const defaultLandscapeImage = '/_img/default/default-image-landscape.svg';

export const getCoverUrl = (cover: MediaAttachment | null | undefined) => {
  const options = { width: 1080, quality: 90 };
  if (cover?.url) return `${ImageSourceHelper.fromUrl(cover.url, options)} 1080w`;
  return ImageSourceHelper.fromUrl(defaultLandscapeImage, options);
};
