import { usePromise } from '@desync/use-promise';
import { Avatar } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment, useEffect, useState } from 'react';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { GetWork } from '_backend/queries/get-work/get-work.types';
import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';
import { Dialog } from '_components/styled/dialog';
import { usePublicAppContext } from '_core/context/public-app-context';

import { workDetailModalConfig } from './config';
import { fetchWork } from './portfolio.api';
import { trackWork } from './tracker';
import { WorkDetailPanel } from './WorkDetailPanel';
import { WorkNavigation } from './WorkNavigation';

type Props = {
  agencyName: string;
  agencySlug: string;
  agencyBrandingPlan: string;
  agencyLogoUrl: string;
  open: boolean;
  onClose: () => void;
  workSlugs: string[] | undefined;
  works?: GetAgencyPortfolio['works'];
  index: number;
  disableCache?: boolean;
  workSlug: string;
  longtailsPage?: boolean;
};

export const WorkDetailModal: FC<Props> = (props) => {
  const {
    agencyName,
    agencySlug,
    agencyBrandingPlan,
    agencyLogoUrl,
    open,
    onClose,
    workSlugs,
    workSlug,
    works,
    index,
    disableCache,
    longtailsPage,
  } = props;
  const { t } = useTranslation(workDetailModalConfig.i18nNamespaces);
  const { locale } = usePublicAppContext();
  const [currentSlug, setCurrentSlug] = useState<string>(workSlug);
  const [currentWork, setCurrentWork] = useState<GetWork | undefined | null>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(index);

  const { data: work, isLoading, error } = usePromise(fetchWork, { workSlug: currentSlug, locale, disableCache });

  const similarWorks = works?.filter(({ slug }) => slug !== workSlug);

  useEffect(() => {
    setCurrentWork(work);
  }, [work]);

  const lastSlide = workSlugs ? currentIndex === workSlugs.length - 1 : true;

  const changeWorkSlug = (index: number) => {
    if (!workSlugs) return;
    setCurrentIndex(index);
    setCurrentSlug(workSlugs[index]);
    trackWork(agencySlug, { slug: workSlugs[index] });
  };

  const nextWork = () => {
    if (lastSlide) return;
    changeWorkSlug(currentIndex + 1);
  };

  const prevWork = () => {
    if (!currentIndex) return;
    changeWorkSlug(currentIndex - 1);
  };

  const openSimilarWork = (work: GetAgencyPortfolio['works'][0]) => {
    trackWork(agencySlug, { name: work?.name, slug: work?.slug });
    setCurrentSlug(work.slug);
  };

  const header = (
    <div className="layout-row flex layout-align-start-center px-16 px-gt-xs-64 pt-32 pb-16">
      <Avatar
        fit="contain"
        src={ImageSourceHelper.fromUrl(agencyLogoUrl, {
          width: 150,
          quality: 95,
        })}
        alt={agencyName}
        avatarUiName={agencyName}
        size="sm"
        shape="square"
        className="mr-16"
      />
      <div className="layout-column lh-1 flex">
        <h4 className="bold text-truncate">{currentWork?.name}</h4>
        <span className="small text-truncate">
          <Trans i18nKey={'portfolio:work.byAgencyName'}>By {{ agencyName }}</Trans>
        </span>
      </div>
    </div>
  );

  const footer = (
    <div className="layout-row layout-align-start-center p-8 shadow-2 hide-gt-xs">
      <DirectBriefingButton
        className="width-100"
        cta="work-modal-footer"
        agencySlug={agencySlug as string}
        size={'sm'}
        buttonStyle="primary"
        buttonVariant="raised"
        label={t('portfolio:cta.btnLabel')}
      />
    </div>
  );

  return (
    <Fragment>
      <Dialog header={header} footer={footer} onClose={onClose} open={open} maxWidth={'md'}>
        <WorkDetailPanel
          agencyName={agencyName}
          agencySlug={agencySlug}
          agencyBrandingPlan={agencyBrandingPlan}
          isLoading={isLoading}
          work={currentWork}
          similarWorks={similarWorks}
          onClick={openSimilarWork}
          longtailsPage={longtailsPage}
        />
      </Dialog>
      {open && <WorkNavigation index={currentIndex} lastSlide={lastSlide} prevWork={prevWork} nextWork={nextWork} />}
    </Fragment>
  );
};
