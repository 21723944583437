import { ArrowDropDownRounded, ArrowDropUpRounded, FiberManualRecord, InfoOutlined } from '@mui/icons-material';
import { Divider, Rating, Tooltip } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { usePublicAppContext } from '_core/context/public-app-context';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { getExpertiseDetails } from '_features/agency/profile/components/expertise';
import * as S from '_features/agency/profile/sections/services/services.styles';

type Props = {
  service: GetAgencyServices['services'][0];
  servicesCount: number;
  totalWorksCount: number;
  toggled: boolean;
  hasInfo: boolean;
  handlePanelToggle: (service: GetAgencyServices['services'][0], experienceLevel: number) => void;
  className?: string;
};

const getExperienceLevel = (worksInService: number, servicesCount: number, totalWorksCount: number) => {
  // Average is 5pts max. distributed based on the number of works in a service compared to other services from the same agency.
  // If an agency has 2 works in a service and 3 in another, it will be given 2pts in the first and 3pts in the second.
  const average = Math.round(Math.min(((5 * servicesCount) / totalWorksCount) * worksInService, 5));
  // Then we add 5pts max. depending of the absolute number of works in a specific service. This gives us a score on 10.
  // If an agency has more than 20 works, we assume the agency is expert in the field and we rate the service level at 10.
  if (worksInService === 0) {
    return 1;
  } // 1 (minimum score) if no work.
  if (worksInService < 3) {
    return Math.ceil(1 + average);
  } // 1 if less than 3 + works % in expertise reported on 5.
  if (worksInService >= 3 && worksInService < 5) {
    return Math.ceil(2 + average);
  } // 2 if between 3 & 5 + works % in expertise reported on 5.
  if (worksInService >= 5 && worksInService < 10) {
    return Math.ceil(3 + average);
  } // 3 if less than 5 & 10 + works % in expertise reported on 5.
  if (worksInService >= 10 && worksInService < 15) {
    return Math.ceil(4 + average);
  } // 4 if less than 10 & 15 + works % in expertise reported on 5.
  if (worksInService >= 15 && worksInService < 20) {
    return Math.ceil(5 + average);
  } // 5 if less than 15 & 20 + works % in expertise reported on 5.
  return 10; // 10 if more than 20 works.
};

export const ServicePreview: React.FC<Props> = (props) => {
  const { service, servicesCount, totalWorksCount, toggled, hasInfo, className } = { ...props };
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale } = usePublicAppContext();
  const experienceLevel = getExperienceLevel(service.works.count, servicesCount, totalWorksCount);

  return (
    <S.PreviewButton
      hasinfo={hasInfo ? 1 : 0}
      buttonVariant="default"
      animation={!hasInfo ? 'none' : 'ripple'}
      onClick={() => props.handlePanelToggle(service, experienceLevel)}
      className="flex-100 bg-neutral-100">
      <div className={clsx(className, 'layout-row layout-align-start-stretch flex py-12 px-8')}>
        <div className="py-4 px-8 layout-column layout-align-center-center">
          <S.ExpertiseIcon className="bg-secondary-300 rounded layout-column layout-align-center-center p-8 flex-noshrink">
            {getExpertiseDetails(t, service.expertise_id)?.icon}
          </S.ExpertiseIcon>
        </div>
        <div className="py-4 px-8 layout-row layout-align-start-center text-left flex">
          <span className="bold h6 text-truncate">{service.name}</span>
          <Tooltip
            arrow
            title={
              <div className="layout-column">
                <span className="bold mb-8 p">
                  <Trans i18nKey={'agency:profile.services.serviceInfoTitle'}>
                    {{ serviceName: service.name }}, what is it?
                  </Trans>
                </span>
                <p className="small">{getExpertiseDetails(t, service.expertise_id)?.description}</p>
              </div>
            }>
            <InfoOutlined className="m-4 text-secondary-500" style={{ fontSize: 14 }} />
          </Tooltip>
        </div>

        <div className="py-4 mx-16 layout-row layout-align-start-center hide show-gt-sm flex-20">
          <div className="layout-row">
            {Array.from(Array(experienceLevel), (number, index) => (
              <FiberManualRecord key={`1-${number}+${index}`} style={{ fontSize: 8 }} />
            ))}
            {Array.from(Array(10 - experienceLevel), (number, index) => {
              return (
                <FiberManualRecord
                  key={`2-${number}+${index}`}
                  style={{ fontSize: 8 }}
                  className="text-secondary-300"
                />
              );
            })}
          </div>
          <span className="small mx-8 flex text-truncate text-left">
            {service.works.count > 0 ? (
              <span className="underline">
                <Trans i18nKey={'agency:profile.services.experiencePreview'} count={service.works.count}>
                  {{ count: service.works.count }} works
                </Trans>
              </span>
            ) : (
              <span>{t('agency:profile.services.noExperience')}</span>
            )}
          </span>
        </div>

        <Divider className="hide show-gt-sm" />

        <div className="text-left py-4 px-16 layout-column layout-align-center-start hide show-gt-sm flex-15">
          <Rating rate={service.reviews.global_rating * 5 || 0} size={14}>
            <span className="small m-4">({service.reviews.count || 0})</span>
          </Rating>
        </div>

        <Divider className="hide show-gt-sm" />

        <div className="py-4 px-16 layout-column hide show-gt-sm flex-15 text-left layout-align-center-start">
          {service.budget.min ? (
            <span className="small width-100 text-truncate">
              <Trans i18nKey={'agency:profile.services.budgetProjectPreview'}>
                <span className="bold">
                  {{
                    budget: new Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: 'EUR',
                      minimumFractionDigits: 0,
                    }).format(service.budget.min),
                  }}
                </span>
                <span>/project</span>
              </Trans>
            </span>
          ) : (
            <span className="small width-100">
              <span className="text-secondary-500">{t('agency:profile.services.noBudgetPreview')}</span>
            </span>
          )}
        </div>

        <div className="py-4 px-16 layout-column layout-align-center-center">
          {hasInfo ? (
            toggled ? (
              <ArrowDropUpRounded style={{ fontSize: 24 }} />
            ) : (
              <ArrowDropDownRounded style={{ fontSize: 24 }} />
            )
          ) : (
            <div className="p-12"></div>
          )}
        </div>
      </div>
    </S.PreviewButton>
  );
};
