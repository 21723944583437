import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  handleToggled: () => void;
  seeMoreBtnLabel?: string | ReactNode;
  seeLessBtnLabel?: string | ReactNode;
  toggled: boolean;
};

export const ToggleButton: React.FC<Props> = (props) => {
  const { className, handleToggled, seeMoreBtnLabel, seeLessBtnLabel, toggled } = { ...props };
  const { t } = useTranslation(['common', 'agency']);

  return (
    <Button
      buttonStyle="secondary"
      buttonVariant="default"
      size="sm"
      className={`${className} small`}
      onClick={handleToggled}
      label={
        !toggled ? (
          <span className="layout-row layout-align-center-center">
            <span className="m-4 underline">{seeMoreBtnLabel ? seeMoreBtnLabel : t('common:clampLines.seeMore')}</span>
            <ArrowDownwardRounded className="m-4" style={{ fontSize: 16 }} />
          </span>
        ) : (
          <span className="layout-row layout-align-center-center">
            <span className="m-4 underline">{seeLessBtnLabel ? seeMoreBtnLabel : t('common:clampLines.seeLess')}</span>
            <ArrowUpwardRounded className="m-4" style={{ fontSize: 16 }} />
          </span>
        )
      }
      id="toggle-btn"
    />
  );
};
