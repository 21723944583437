import styled from '@emotion/styled';
import { GoogleMap } from '@react-google-maps/api';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const ScrollingLocations = styled.div`
  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    max-height: 16rem;
  }
`;

export const MapContainer = styled.div`
  min-height: 10rem;
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    height: 30rem;
  }
`;

export const Map = styled(GoogleMap)`
  width: 100%;
  height: 100%;
`;

export const LocationIcon = styled.div`
  top: 0;
`;

export const Location = styled.div`
  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    &:hover > div {
      background-color: ${COLORS.SECONDARY_200};
    }
  }
`;

export const SocialIcon = styled.img`
  height: 1em;
  width: 1em;
  opacity: 0.75;
`;

export const EmptyDiv = styled.div`
  width: 640px;
`;
