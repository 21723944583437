import { isSafeInteger } from '@sortlist-frontend/utils';
import { FC } from 'react';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { WorkCardWithModal } from '_features/agency/profile/sections/portfolio/components/work-card-with-modal';

type PortfolioWorksListProps = {
  works: GetAgencyPortfolio['works'];
  agencySlug: string;
  agencyName: string;
  agencyLogoUrl: string;
  /** Above this number, set an hidden class
   * to disable send 0, null or undefined */
  maxVisible?: number | null;
  children?: never;
  disableCache?: boolean;
};

const defaultWorkCardList = {
  maxVisible: 6,
  disableCache: false,
};

export const PortfolioWorksList: FC<PortfolioWorksListProps> = (props) => {
  const {
    works = [],
    maxVisible,
    agencySlug,
    agencyName,
    agencyLogoUrl,
    disableCache,
  } = { ...defaultWorkCardList, ...props };
  const enableHidden = isSafeInteger(maxVisible) && maxVisible > 0;
  const maxDisplayed = enableHidden && maxVisible ? maxVisible : Number.MAX_SAFE_INTEGER;
  const workSlugs = works?.map((work) => work.slug);
  return (
    <>
      {Array.isArray(works) &&
        works.slice(0, maxDisplayed).map((work, index) => {
          //const isHidden = index > maxDisplayed;
          return (
            <div key={work.slug} className={'p-8 flex-100 flex-sm-50 flex-gt-sm-33'}>
              <WorkCardWithModal
                key={work.slug}
                work={work}
                agencyName={agencyName}
                agencySlug={agencySlug}
                agencyLogoUrl={agencyLogoUrl}
                agencyWorkSlugs={workSlugs}
                agencyWorks={works}
                currentIndex={index}
                disableCache={disableCache}
              />
            </div>
          );
        })}
    </>
  );
};
