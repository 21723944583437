import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

type SliderButtonProps = {
  direction: 'back' | 'forward';
};

export const SliderButton = styled.div`
  position: absolute;
  top: 50%;
  z-index: 3000;

  ${(props: SliderButtonProps) =>
    props.direction === 'back' &&
    `left: 0.5rem;

    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      left: 20px;
    }`}
  ${(props: SliderButtonProps) =>
    props.direction === 'forward' &&
    `right: 0.5rem;

    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      right: 20px;
    }`}
`;
