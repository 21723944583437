import { isParsableNumeric, isParsableSafeInteger } from './typeguards';

export const stringToSafeInteger = (value: string | number | null | undefined | boolean): number | null => {
  if (!isParsableSafeInteger(value)) {
    return null;
  }
  return typeof value === 'string' ? Number.parseInt(value, 10) : value;
};

export const stringToFloat = (value: string | number | null | undefined | boolean): number | null => {
  if (!isParsableNumeric(typeof value === 'number' ? value.toString(10) : value ?? '')) {
    return null;
  }
  const v = Number.parseFloat(typeof value === 'string' ? value : (value as number).toString(10));
  return Number.isNaN(v) ? null : v;
};

export const intToString = (value: string | number | null | undefined | boolean): string | null => {
  const parsed = typeof value === 'number' ? value.toString(10) : value;
  if (!isParsableSafeInteger(parsed)) {
    return null;
  }
  return parsed;
};

export const hexToRgbA = (hex: string) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let hue: any = undefined;
    hue = hex.substring(1).split('');
    if (hue.length === 3) {
      hue = [hue[0], hue[0], hue[1], hue[1], hue[2], hue[2]];
    }
    hue = '0x' + hue.join('');
    return [(hue >> 16) & 255, (hue >> 8) & 255, hue & 255].join(',');
  }
  throw new Error('There was an error with the hex color.');
};
