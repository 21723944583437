import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const RatingBlock = styled.section`
  background-color: transparent;

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    background-color: #fff8ed;

    span {
      color: ${COLORS.WARNING_700};
    }
  }
`;

export const RatingHead = styled.section`
  background-color: transparent;

  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    background-color: #fff8ed;
  }
`;
