import { ArrowForwardRounded, PlaceRounded, PublicTwoTone } from '@mui/icons-material';
import { Divider, useMediaQuery } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { getStorage, isNonEmptyPlainObject } from '@sortlist-frontend/utils';
import clsx from 'clsx';
import { FC, Fragment, useEffect, useState } from 'react';

import { Office } from '_backend/queries/get-agency-offices/get-agency-offices.types';
import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { useGetAgencyOffices, useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';
import { GoogleMapCtn } from '_features/agency/profile/sections/contact/google-map.ctn';
import { hasBrandingModule } from '_features/agency/profile/utils/has-branding-module';

import * as S from './contact.styles';
import { isValidSocialMediaKey, socialMediaIconsList } from './social-media-icons.data';
import { hasFreeBrandingModule } from './utils';

type Props = {
  children?: never;
  className?: string;
  /**
   * Whether to disable google maps loading (ie for mobile)...
   * Later on you can also refactor and just display an image
   */
  disableGoogleMaps?: boolean;
};

const defaultProps: Partial<Props> = {
  disableGoogleMaps: false,
};

export const ContactPanel: FC<Props> = (props) => {
  const { className } = { ...defaultProps, ...props };
  const { media } = useMediaQuery();
  const { track } = useTracker();
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const { data } = useGetAgencyOffices(agencySlug, locale as string);
  const [sessionEntryUrl, setSessionEntryUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = getStorage('sessionEntryUrl') || window.location.href;
      setSessionEntryUrl(url.replace(/"/g, ''));
    }
  }, []);

  const {
    name,
    website_url: agencyWebsite,
    social_data: agencySocialData,
    branding_plan: brandingModule,
  } = agency ?? { name: '', branding_plan: '' };

  const showSocialMedia = isNonEmptyPlainObject(agencySocialData) && hasBrandingModule(brandingModule);

  return (
    <SectionBox
      id="contact"
      className={clsx(className, 'py-gt-xs-96 py-xs-64')}
      title={t('agency:profile.contact.title')}
      subtitle={
        <Trans i18nKey={'agency:profile.contact.subtitle'}>
          <b>Contact details</b> of {{ name }}
        </Trans>
      }>
      <div className="px-gt-sm-64 px-32 relative">
        <div className="border border-secondary-900 rounded-lg shadow-2 overflow-hidden layout-column layout-gt-sm-row">
          {media.gtSm && data && (
            <GoogleMapCtn
              className="width-100 width-gt-sm-65"
              agencyName={name}
              agencySlug={name}
              offices={data}
              selectedOffice={selectedOffice}
            />
          )}
          <div className="layout-column flex-gt-sm-35">
            <p className="bold h6 pt-gt-xs-48 pt-16 pb-16 px-16">{t('agency:profile.contact.detailsTitle')}</p>
            <div className="p-16 layout-column">
              <DirectBriefingButton
                className="flex"
                cta="contact-panel"
                agencySlug={agencySlug as string}
                size={'md'}
                buttonStyle="primary"
                buttonVariant="raised"
                label={t('agency:profile.contact.contactBtnLabel', { name })}
              />
            </div>
            {agencyWebsite != null && (
              <Fragment>
                <Divider />
                <div className="layout-row layout-align-start-center px-16">
                  <PublicTwoTone style={{ fontSize: 16 }} />
                  <Obfuscate
                    obfuscate={hasFreeBrandingModule(brandingModule)}
                    role="link"
                    href={`${agencyWebsite}?utm_source=sortlist&utm_medium=profile&utm_campaign=${sessionEntryUrl}`}
                    target="_blank"
                    rel={hasFreeBrandingModule(brandingModule) ? 'nofollow' : 'noopener'}
                    className="btn small text-truncate btn-default btn-secondary btn-md flex text-left px-16"
                    onClick={() =>
                      track('websiteOpened', {
                        agencyName: name,
                        agencySlug,
                        page: 'agency-public-profile',
                        element: 'contact-cta',
                      })
                    }>
                    {agencyWebsite}
                  </Obfuscate>
                  <ArrowForwardRounded style={{ fontSize: 16 }} />
                </div>
              </Fragment>
            )}
            {data != null && data.offices.length > 0 && (
              <Fragment>
                <Divider />
                <S.ScrollingLocations className="bg-secondary-100 layout-row overflow-y-scroll">
                  <S.LocationIcon className="sticky layout-column layout-align-start-start py-24 pl-16 pr-4">
                    <PlaceRounded style={{ fontSize: 16 }} />
                  </S.LocationIcon>
                  <ul className="layout-column list-reset flex">
                    {data.offices.map((office, index) => (
                      <li key={office.position} className="layout-column">
                        <S.Location
                          className="cursor-pointer pl-0 pr-12 py-4"
                          onClick={() => setSelectedOffice(office)}>
                          <div
                            className={clsx(
                              { 'bg-secondary-300': office.slug === selectedOffice?.slug && media.gtSm },
                              'small layout-column py-8 px-12 rounded-sm',
                            )}>
                            {office.position === 1 && (
                              <span className="bold">{t('agency:profile.contact.headquarter')}</span>
                            )}
                            <span className="text-break-word">{office.address}</span>
                          </div>
                        </S.Location>
                        {index + 1 !== data.offices.length ? <Divider className="ml-0" /> : null}
                      </li>
                    ))}
                  </ul>
                </S.ScrollingLocations>
                <Divider />
                {showSocialMedia && (
                  <div className="layout-row p-16">
                    {Object.keys(agencySocialData).map(
                      (key) =>
                        key !== 'website' &&
                        isValidSocialMediaKey(key) &&
                        agencySocialData[key] != null && (
                          <Obfuscate
                            role={'link'}
                            obfuscate={true}
                            href={agencySocialData[key] as string}
                            onClick={() => track('socialMediaOpened', { agencyName: name, agencySlug, type: key })}
                            target="_blank"
                            rel="nofollow"
                            key={`${name}-${key}`}
                            className="mr-8 text-secondary-700 bg-secondary-300 rounded layout-column layout-align-center-center p-8">
                            <S.SocialIcon src={socialMediaIconsList[key]} alt={`${name}-${key}`} />
                          </Obfuscate>
                        ),
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </SectionBox>
  );
};
