// Filter undefined and null from params
import { isPresent } from '@sortlist-frontend/utils';

export const getFilteredQueryParams = <T extends Record<string, unknown>, K extends keyof T>(
  params: T,
): {
  [key in K]-?: Exclude<T[key], null>;
} => {
  return Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (isPresent(value)) {
        return { ...acc, ...{ [key]: value } };
      }
      return acc;
      // this typing is not correct, best effort and using an opaque type
    },
    {} as { [key in K]-?: Exclude<T[key], null> },
  );
};
