import { Button, Divider } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import type { ClassAttributes, FC, HTMLAttributes, ReactNode } from 'react';

import { useSharedComponentsContext } from '../utils';
import { About } from './About';
import { Description } from './Description';
import { Rating } from './Rating';
import { Review } from './types';
import { User } from './User';
import { getFormattedRating } from './utils';

export type ReviewCardProps = {
  className?: string;
  review: Review;
  // If provided, it will render instead of the default reply elements
  replyComponent?: ReactNode;
  agencyName: string;
  reportUrl?: string;
} & ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement>;

export const ReviewCard: FC<ReviewCardProps> = (props) => {
  const { t } = useSharedComponentsContext();
  const { review, agencyName, className, reportUrl, replyComponent, ...restProps } = props;

  return (
    <div
      data-testid="review-card"
      className={clsx(className, 'layout-gt-sm-row layout-xs-column layout-sm-column sortlist-review_container')}
      {...restProps}>
      <div
        data-testid="review-card-user-section"
        className="p-24 flex-gt-sm-20 layout-column flex-order-0 flex-order-gt-sm-0 bg-secondary-100 sortlist-review_container_user">
        <User
          review={review}
          className="layout-gt-sm-column layout-row layout-align-start-center layout-align-gt-sm-start-start mr-8"
        />
        <About className="hide show-gt-sm" review={review} />
      </div>

      <div className="p-24 flex flex-order-1 flex-order-gt-sm-3 layout-column layout-align-start-center">
        <Rating getRating={getFormattedRating} review={review} />
        {reportUrl && (
          <div className="mt-16 flex layout-column layout-align-end-start">
            <Button
              label={t('base:reviewCard.reportReview')}
              buttonStyle={'secondary'}
              className="text-center btn btn-secondary btn-light btn-xs"
              onClick={() => {
                window.open(reportUrl, '_blank');
              }}
              data-testid="report-review-button"
            />
          </div>
        )}
      </div>

      <Description
        className="flex-none flex-gt-sm-60 flex-order-2 flex-order-gt-sm-1"
        review={review}
        replyComponent={replyComponent}
        agencyName={agencyName}
      />
      <Divider className="my-16 hide show-gt-sm flex-order-gt-sm-2" />
      <About className="hide-gt-sm flex-order-4 px-24 pb-32" review={review} />
    </div>
  );
};
