import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { FC } from 'react';

import { ArrowBackwardButton, ArrowForwardButton } from '../styles';
import * as S from './styles';

type Props = {
  index: number;
  lastSlide: boolean;
  prevWork: () => void;
  nextWork: () => void;
};

export const WorkNavigation: FC<Props> = (props) => {
  const { index, lastSlide, prevWork, nextWork } = props;

  return (
    <>
      {index > 0 && (
        <S.SliderButton direction="back" className="hide-xs">
          <ArrowBackwardButton>
            <Button
              fab={true}
              size="sm"
              iconSize="md"
              buttonStyle="secondary"
              buttonVariant="flat"
              className="shadow-3-content-900"
              icon={<ArrowBackRounded />}
              onClick={() => prevWork()}
              id="previous-work-btn"
            />
          </ArrowBackwardButton>
        </S.SliderButton>
      )}
      {!lastSlide && (
        <S.SliderButton direction="forward" className="hide-xs">
          <ArrowForwardButton>
            <Button
              fab={true}
              size="sm"
              iconSize="md"
              buttonStyle="secondary"
              buttonVariant="flat"
              className="shadow-3-content-900"
              icon={<ArrowForwardRounded />}
              onClick={() => nextWork()}
              id="next-work-btn"
            />
          </ArrowForwardButton>
        </S.SliderButton>
      )}
    </>
  );
};
