import { ImageSourceHelper } from '@sortlist-frontend/media';
import { getJsonSafeString } from '@sortlist-frontend/utils';
import { CollectionPageJsonLd } from 'next-seo';
import { FC } from 'react';

import { GetAgencySeoDetails } from '_backend/queries/get-agency-seo-details/get-agency-seo-details.types';

type Props = {
  agencyName: string;
  seoDetails: GetAgencySeoDetails | null;
};

export const getSafeSeoWorkParts = (works: GetAgencySeoDetails['works'], agencyName: string) =>
  works.map((work) => {
    return {
      author: getJsonSafeString(agencyName),
      audience: getJsonSafeString(work.audience),
      about: getJsonSafeString(work.about),
      thumbnailUrl: ImageSourceHelper.fromUrl(work.thumbnail.url, {
        width: 800,
      }),
      keywords: getJsonSafeString(work.keywords?.join()),
      name: getJsonSafeString(work.name),
      datePublished: work.datePublished,
    };
  });

export const CollectionPage: FC<Props> = (props) => {
  const { agencyName, seoDetails } = props;

  return (
    <CollectionPageJsonLd
      name={`${getJsonSafeString(agencyName)} Portfolio`}
      hasPart={getSafeSeoWorkParts(seoDetails?.works || [], agencyName)}
    />
  );
};
