import { CheckCircleTwoTone } from '@mui/icons-material';
import { Rating as RatingDS, Tooltip } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import type { FC } from 'react';

import { useSharedComponentsContext } from '../../utils';
import { Review } from '../types';
import * as S from './styles';

type Props = {
  className?: string;
  getRating: (rating?: number | null) => number;
  review: Review;
  children?: never;
};

export const Rating: FC<Props> = (props) => {
  const { className, getRating, review } = { ...props };
  const { t } = useSharedComponentsContext();

  const { descriptionPros, rating, ratingBudget, ratingCollaboration, ratingQuality, ratingSchedule, verifiedAt } =
    review;

  return (
    <div data-testid="review-card-rating" className={clsx(className, 'sortlist-review_rating_container')}>
      <S.RatingBlock className="rounded-lg">
        <S.RatingHead
          data-testid="review-card-rating-general"
          className="border-bottom border-secondary-100 rounded-sm p-16 layout-row layout-gt-sm-column layout-align-gt-sm-center-center layout-align-space-between-center bg-accent-100">
          <div className="layout-row layout-align-gt-sm-center-end layout-align-start-center text-warning-700 text-truncate">
            <span className="h4 bold">{getRating(rating)}</span>
            <span className="p">{'/5'}</span>
          </div>
          <RatingDS className="p-4 text-center" rate={getRating(rating)} size={16} />
        </S.RatingHead>
        {verifiedAt && (
          <div className="py-8">
            <Tooltip title={t('base:reviewCard.verifiedTooltip')}>
              <div className="px-8 py-4 bg-secondary-200 text-secondary-500 rounded-xs small layout-row layout-align-center-center gap-4">
                <CheckCircleTwoTone className="rounded p" />
                <span className="small">{t('base:reviewCard.verifiedText')}</span>
              </div>
            </Tooltip>
          </div>
        )}
        {descriptionPros && (
          <div data-testid="review-card-rating-advanced" className="py-8">
            <div
              data-testid="review-card-rating-budget"
              className="text-center layout-row layout-gt-sm-column layout-align-gt-sm-center-center layout-align-space-between-center px-16 py-8">
              <span className="small mb-gt-sm-8 text-truncate">{t('base:reviewCard.budgetTitle')}</span>
              <RatingDS rate={getRating(ratingBudget)} size={14} className="flex-none" />
            </div>
            <div
              data-testid="review-card-rating-advanced-quality"
              className="text-center layout-row layout-gt-sm-column layout-align-gt-sm-center-center layout-align-space-between-center px-16 py-8">
              <span className="small mb-gt-sm-8 text-truncate">{t('base:reviewCard.qualityTitle')}</span>
              <RatingDS rate={getRating(ratingQuality)} size={14} className="flex-none" />
            </div>
            <div
              data-testid="review-card-rating-advanced-schedule"
              className="text-center layout-row layout-gt-sm-column layout-align-gt-sm-center-center layout-align-space-between-center px-16 py-8">
              <span className="small mb-gt-sm-8 text-truncate">{t('base:reviewCard.scheduleTitle')}</span>
              <RatingDS rate={getRating(ratingSchedule)} size={14} className="flex-none" />
            </div>
            <div
              data-testid="review-card-rating-advanced-collaboration"
              className="text-center layout-row layout-gt-sm-column layout-align-gt-sm-center-center layout-align-space-between-center px-16 py-8">
              <span className="small mb-gt-sm-8 text-truncate">{t('base:reviewCard.collaborationTitle')}</span>
              <RatingDS rate={getRating(ratingCollaboration)} size={14} className="flex-none" />
            </div>
          </div>
        )}
      </S.RatingBlock>
    </div>
  );
};
