import { getFormattedRating } from '@sortlist-frontend/shared-components';
import { getJsonSafeString } from '@sortlist-frontend/utils';
import { LocalBusinessJsonLd } from 'next-seo';
import { Fragment } from 'react';

import { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import { GetAgencyReviews } from '_backend/queries/get-agency-reviews/get-agency-reviews.types';
import { GetAgencySeoDetails } from '_backend/queries/get-agency-seo-details/get-agency-seo-details.types';
import { usePublicAppContext } from '_core/context/public-app-context';

import { AgencyStats } from '../../utils';
import { seoAreas, seoServices } from '../profile.seo';

type Props = {
  agencyStats: AgencyStats;
  agency: GetAgencyProfile;
  reviews: GetAgencyReviews | null;
  seoDetails: GetAgencySeoDetails | null;
  logo: string | undefined;
};

export const LocalBusiness: React.FC<Props> = (props) => {
  const { agencyStats, agency, reviews: reviewsData, seoDetails, logo } = props;
  const { social_data } = agency;
  const { getUrl } = usePublicAppContext();
  const reviews = reviewsData?.reviews ?? [];

  const socialUrls: string[] = [];
  if (social_data) {
    Object.values(social_data).map((social) => {
      if (social) socialUrls.push(social);
    });
  }

  return (
    <LocalBusinessJsonLd
      type="LocalBusiness"
      id={getUrl('agency.profile', { slug: agency.slug })}
      name={getJsonSafeString(agency.name)}
      url={getUrl('agency.profile', { slug: agency.slug })}
      images={[logo || '']} // or default
      priceRange={
        agencyStats.servicesStats.minBudget && agencyStats.servicesStats.maxBudget
          ? `${agencyStats.servicesStats.minBudget} € - ${agencyStats.servicesStats.maxBudget} €`
          : '10000 € - 100000 €'
      }
      address={{
        addressLocality: agency.locality || '',
        streetAddress: agency.address || '',
        postalCode: '',
        addressCountry: agency.country || '',
      }}
      geo={{
        latitude: agency.latitude ? String(agency.latitude) : '',
        longitude: agency.longitude ? String(agency.longitude) : '',
      }}
      telephone="#phone"
      description={getJsonSafeString(agency.description)}
      sameAs={socialUrls}
      rating={
        agencyStats.reviewsScore && agencyStats.reviewsCount > 0
          ? {
              ratingCount: String(agencyStats.reviewsCount),
              ratingValue: String(getFormattedRating(agencyStats.reviewsScore)),
            }
          : undefined
      }
      review={reviews.map((review) => {
        return {
          author: review.user_full_name ? review.user_full_name : '',
          datePublished: review.updated_at ? review.updated_at : '', // we need validated_at here
          reviewBody: getJsonSafeString(review.description_summary),
          reviewRating: {
            ratingValue: getFormattedRating(review.rating).toString(),
          },
        };
      })}
      areaServed={seoAreas(seoDetails?.prospect_areas)}
      makesOffer={seoServices(seoDetails?.services)}
    />
  );
};
