import {
  AssignmentTwoTone,
  BrushTwoTone,
  CollectionsTwoTone,
  CompareTwoTone,
  EmailTwoTone,
  EmojiEventsTwoTone,
  PeopleTwoTone,
  QuestionAnswerTwoTone,
  StarTwoTone,
} from '@mui/icons-material';

export const SectionsDetails = [
  { id: 'about', label: 'About', icon: <AssignmentTwoTone style={{ fontSize: 16 }} /> },
  { id: 'services', label: 'Services', icon: <BrushTwoTone style={{ fontSize: 16 }} /> },
  { id: 'similar', label: 'Similar agencies', icon: <CompareTwoTone style={{ fontSize: 16 }} /> },
  { id: 'portfolio', label: 'Portfolio', icon: <CollectionsTwoTone style={{ fontSize: 16 }} /> },
  { id: 'team', label: 'Team', icon: <PeopleTwoTone style={{ fontSize: 16 }} /> },
  { id: 'awards', label: 'Awards', icon: <EmojiEventsTwoTone style={{ fontSize: 16 }} /> },
  { id: 'reviews', label: 'Reviews', icon: <StarTwoTone style={{ fontSize: 16 }} /> },
  { id: 'faq', label: 'FAQ', icon: <QuestionAnswerTwoTone style={{ fontSize: 16 }} /> },
  { id: 'contact', label: 'Contact', icon: <EmailTwoTone style={{ fontSize: 16 }} /> },
];
