import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS, SHADOWS } from '@sortlist-frontend/design-system';

export const Shape = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
`;

export const ReviewContainer = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    border: 1px solid ${COLORS.SECONDARY_900};
    border-radius: 0.625rem;
    box-shadow: ${SHADOWS.DEPTH_2};
    overflow: hidden;
  }
`;

export const ReviewCTA = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    text-align: center;
  }
`;
export const Description = styled.p`
  white-space: pre-line;
`;
