import { AssignmentTwoTone, InfoOutlined } from '@mui/icons-material';
import { Divider, Tooltip, useMediaQuery } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { CtaBox } from '_components/common/CtaBox';
import { ToggleButton } from '_components/common/ToggleButton';
import { usePublicAppContext } from '_core/context/public-app-context';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';
import * as C from '_features/agency/profile/sections/services/components';

import { useGetAgencyProfile } from '../../../../../core/repos/agency-profile.repo';
import * as S from './services.styles';

type Props = {
  services: GetAgencyServices;
  agencyLogoUrl: string;
  className?: string;
  handleActivePanel: () => void;
};

const getTotalWorkCount = (services: { works: { count: number } }[]): number => {
  return services.reduce((worksCount, service) => worksCount + service.works.count, 0);
};

export const maxNumberOfServicesDisplayed = 10;

export const ServicesPanel: FC<Props> = (props) => {
  const { agencyLogoUrl, className, handleActivePanel, services: data } = props;
  const { media } = useMediaQuery();
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);

  const { name } = agency || {};

  const [toggledAllExpertises, setToggledAllExpertises] = useState(false);

  const handleToggledAllExpertises = () => {
    setToggledAllExpertises(!toggledAllExpertises);
  };

  useEffect(() => {
    if (media.xs) {
      setToggledAllExpertises(true);
    }
  }, [media]);

  return (
    <SectionBox
      id="services"
      className={clsx(className, 'py-gt-xs-96 py-xs-64')}
      title={t('agency:profile.services.title')}
      subtitle={
        <Trans i18nKey={'agency:profile.services.subtitle'} count={data ? data.services.length : 0}>
          <b>
            <>{{ count: data ? data.services.length : 0 }} services</>
          </b>
          offered by {{ name }}
        </Trans>
      }>
      {data ? (
        <div className="px-gt-sm-64 px-gt-xs-32">
          <div className="layout-row py-8 pr-16 pl-6 hide show-gt-sm">
            <span
              title={t('agency:profile.services.serviceName')}
              className="small text-secondary-900 flex px-16 text-truncate">
              {t('agency:profile.services.serviceName')}
            </span>
            <div className="flex-20 mx-16 layout-row layout-align-start-center">
              <span
                title={t('agency:profile.services.experienceLevel.label')}
                className="small  text-truncate text-secondary-900">
                {t('agency:profile.services.experienceLevel.label')}
              </span>
              <Tooltip
                title={
                  <div className="layout-column">
                    <span className="bold mb-8 p">{t('agency:profile.services.experienceLevel.title')}</span>
                    <p className="small">
                      <Trans i18nKey={'agency:profile.services.experienceLevel.description'}>
                        The experience helps you to assess {{ agencyName: name }} level of expertise calculated based on
                        the absolute number of works delivered for a specific service but also in comparison with the
                        number of works in other services offered by the agency.
                      </Trans>
                    </p>
                  </div>
                }>
                <InfoOutlined className="m-4 text-secondary-900" style={{ fontSize: 14 }} />
              </Tooltip>
            </div>
            <Divider />
            <span
              title={t('agency:profile.services.relatedReviews')}
              className="small text-secondary-900 flex-15 px-16 text-truncate">
              {t('agency:profile.services.relatedReviews')}
            </span>
            <Divider />
            <span
              title={t('agency:profile.services.startingBudget')}
              className="small text-secondary-900 flex-15 mr-48 px-16 text-truncate">
              {t('agency:profile.services.startingBudget')}
            </span>
          </div>
          <S.Shape className="rounded-lg list-reset overflow-hidden shadow-2 layout-column bg-secondary-100 border border-secondary-700">
            {(data.services.length > maxNumberOfServicesDisplayed && !toggledAllExpertises
              ? data.services.slice(0, maxNumberOfServicesDisplayed)
              : data.services
            ).map((service, index) => (
              <C.ServiceCard
                index={index}
                visibleServicesCount={maxNumberOfServicesDisplayed}
                key={`${service.name}-${index}`}
                agencyName={name as string}
                agencySlug={agencySlug}
                agencyLogoUrl={agencyLogoUrl}
                service={service}
                servicesCount={data.services.length}
                handleActivePanel={handleActivePanel}
                totalWorksCount={getTotalWorkCount(data.services)}></C.ServiceCard>
            ))}
            {data && data.services.length > maxNumberOfServicesDisplayed && !media.xs ? (
              <ToggleButton
                handleToggled={() => handleToggledAllExpertises()}
                seeMoreBtnLabel={
                  <Trans
                    i18nKey={'agency:profile.services.seeOtherServices'}
                    count={data.services.length - maxNumberOfServicesDisplayed}>
                    See all other services ({{ count: data.services.length - maxNumberOfServicesDisplayed }})
                  </Trans>
                }
                seeLessBtnLabel={t('agency:profile.services.seeLessServices')}
                toggled={toggledAllExpertises}
              />
            ) : null}
          </S.Shape>
          <footer className="p-32 layout-row layout-align-center-center hide-xs">
            <CtaBox
              title={t('agency:profile.services.cta.title')}
              subtitle={t('agency:profile.services.cta.subtitle', { name })}
              btnLabel={t('agency:profile.services.cta.btnLabel')}
              icon={<AssignmentTwoTone />}
              element="services-panel"
              agencySlug={agencySlug}
            />
          </footer>
        </div>
      ) : null}
    </SectionBox>
  );
};
