import { formatDate } from '@sortlist-frontend/utils';
import clsx from 'clsx';
import { useEffect, useState, type FC } from 'react';

import { useSharedComponentsContext } from '../../utils';
import { Review } from '../types';

type Props = {
  review: Review;
  className?: string;
};

const getTeamSize = (review: Review) => {
  const { companySizeMin, companySizeMax } = review;
  if (companySizeMin && companySizeMax) {
    return `${companySizeMin} - ${companySizeMax}`;
  }
  if (companySizeMin) {
    return companySizeMin;
  }
  if (companySizeMax) {
    return companySizeMax;
  }
  return undefined;
};

export const About: FC<Props> = (props) => {
  const { review, className } = { ...props };
  const { t, locale } = useSharedComponentsContext();
  const [reviewDate, setReviewDate] = useState<string | null>(null);

  const { expertiseName, sectorName, createdAt } = review;

  useEffect(() => {
    setReviewDate(
      formatDate(createdAt, locale, undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })!,
    );
  }, []);

  return (
    <div
      data-testid="review-card-about"
      className={clsx(
        className,
        'layout-column layout-align-start-start flex text-break-word sortlist-review_about_container',
      )}>
      {expertiseName && (
        <div className="small layout-column">
          <span className="bold">{t('base:reviewCard.serviceTitle')}</span>
          <span data-testid="review-card-about-expertise">{expertiseName}</span>
        </div>
      )}
      {sectorName && (
        <div className="small my-8 layout-column">
          <span className="bold">{t('base:reviewCard.sectorTitle')}</span>
          <span data-testid="review-card-about-sector">{sectorName}</span>
        </div>
      )}
      {getTeamSize(review) && (
        <div className="small layout-column">
          <span className="bold">{t('base:reviewCard.teamTitle')}</span>
          <span data-testid="review-card-about-team-size">{getTeamSize(review)}</span>
        </div>
      )}
      {reviewDate ? (
        <div className="mt-16 flex layout-column layout-align-end-start">
          <span className="small" data-testid="review-card-about-review-date">
            {reviewDate}
          </span>
        </div>
      ) : null}
    </div>
  );
};
