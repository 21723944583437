import { getJsonSafeString } from '@sortlist-frontend/utils';

import { GetAgencySeoDetails } from '_backend/queries/get-agency-seo-details/get-agency-seo-details.types';

type Params = {
  agencyName: string;
  reviewsCount: number;
  locality: string | null;
  reviewText?: string;
};

export const getPageTitle = (params: Params): string => {
  const { agencyName, reviewsCount, locality, reviewText = 'reviews' } = params;

  if (reviewsCount <= 0) {
    if (locality) {
      return `${agencyName}, ${locality} | Sortlist`;
    }
    return `${agencyName} | Sortlist`;
  } else if (reviewsCount > 0 && reviewsCount < 3) {
    return `${agencyName} (+ ${reviewText}) | Sortlist`;
  }
  return `${agencyName} (+ ${reviewsCount} ${reviewText}) | Sortlist`;
};

export const seoAreas = (prospectAreas: GetAgencySeoDetails['prospect_areas'] | undefined) =>
  prospectAreas
    ? prospectAreas.map((area) => ({
        geoMidpoint: {
          latitude: area.latitude,
          longitude: area.longitude,
        },
        geoRadius: area.radius,
      }))
    : [];

export const seoServices = (services: GetAgencySeoDetails['services'] | undefined) =>
  services
    ? services.map((service) => ({
        priceSpecification: {
          type: 'UnitPriceSpecification',
          priceCurrency: 'EUR',
          price: `${service.budgetMin} - ${service.budgetMax}`,
        },
        itemOffered: {
          name: service.name,
          description: getJsonSafeString(service.description) || '',
        },
      }))
    : [];
