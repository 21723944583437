import { Divider } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useState } from 'react';

import { GetAgencyAwards } from '_backend/queries/get-agency-awards/get-agency-awards.types';
import { ToggleButton } from '_components/common/ToggleButton';
import { usePublicAppContext } from '_core/context/public-app-context';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';

import { useGetAgencyProfile } from '../../../../../core/repos/agency-profile.repo';
import { SectionBox } from '../../components';
import { Award } from './components/award.component';

type Props = {
  children?: never;
  agencyLogoUrl: string;
  className?: string;
  awardsData: GetAgencyAwards;
};

export const AwardsPanel: FC<Props> = (props) => {
  const {
    agencyLogoUrl,
    className,
    awardsData: { awards },
  } = props;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const [toggledAllAwards, setToggledAllAwards] = useState(false);
  const { name } = agency || {};

  return (
    <SectionBox
      id="awards"
      title={t('agency:profile.awards.title')}
      className={clsx(className, 'py-gt-xs-96 py-xs-64')}
      subtitle={
        <Trans i18nKey={'agency:profile.awards.subtitle'} count={awards?.length}>
          {{ name }} was
          <b>
            <>awarded {{ count: awards?.length }} times</>
          </b>
        </Trans>
      }>
      {awards ? (
        <div className="px-gt-sm-56 px-24 relative">
          <div className="layout-xs-column layout-row layout-wrap layout-align-start-stretch">
            {awards.map((award, index: number) => (
              <div
                key={`${award.name}-${index}`}
                className={clsx(
                  index > 3 && !toggledAllAwards ? 'hide' : '',
                  'flex-100 flex-sm-50 flex-gt-sm-25 layout-column',
                )}>
                <Award
                  agencyName={name as string}
                  agencySlug={agencySlug}
                  agencyLogoUrl={agencyLogoUrl}
                  award={award}
                  className="flex"></Award>
              </div>
            ))}
          </div>
          {(awards ?? []).length > 4 && (
            <>
              {toggledAllAwards && <Divider />}
              <div className="layout-column layout-align-cener-center">
                <ToggleButton
                  handleToggled={() => setToggledAllAwards(!toggledAllAwards)}
                  seeMoreBtnLabel={
                    <Trans i18nKey={'agency:profile.awards.seeMoreAwards'} count={awards.length}>
                      See all other awards ({{ count: awards.length - 3 }})
                    </Trans>
                  }
                  seeLessBtnLabel={t('agency:profile.awards.seeLessAwards')}
                  toggled={toggledAllAwards}
                />
              </div>
            </>
          )}
        </div>
      ) : null}
    </SectionBox>
  );
};
