import { ArrowBackRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import clsx from 'clsx';

import * as S from './mobile-bar.styles';

type Props = {
  agencyName: string;
  page: string | undefined;
  className?: string;
  onClick: () => void;
};

export const MobileBar: React.FC<Props> = (props) => {
  const { agencyName, page, className, onClick } = props;

  return (
    <S.Shape
      className={clsx(
        className,
        'hide-gt-xs shadow-2 layout-row layout-align-start-center bg-secondary-100 border-bottom border-top width-100 border-secondary-500 py-8 px-16 sticky',
      )}>
      <div className="flex">
        <Button
          onClick={onClick}
          icon={<ArrowBackRounded style={{ fontSize: 16 }} />}
          buttonVariant="default"
          fab
          buttonStyle="secondary"
          size="xs"
        />
      </div>
      <div className="flex small layout-column layout-align-center-center">
        <span className="bold">{agencyName}</span>
        <span>{page}</span>
      </div>
      <div className="flex"></div>
    </S.Shape>
  );
};
