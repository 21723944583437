import { FC, useCallback, useState } from 'react';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { WorkDetailModal } from '_components/common/WorkDetailModal';
import { trackWork } from '_components/common/WorkDetailModal/tracker';
import { WorkCard } from '_components/common/WorkDetailModal/WorkCard';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';

type Props = {
  agencyName: string;
  agencySlug: string;
  agencyLogoUrl: string;
  work: GetAgencyPortfolio['works'][0];
  agencyWorkSlugs: string[] | undefined;
  agencyWorks: GetAgencyPortfolio['works'];
  currentIndex: number;
  onClick?: (workSlug: string) => void;
  disableCache: boolean;
};

export const WorkCardWithModal: FC<Props> = (props) => {
  const { agencyName, agencySlug, agencyLogoUrl, work, agencyWorkSlugs, agencyWorks, currentIndex, disableCache } =
    props;
  const [open, setOpen] = useState(false);
  const { locale } = usePublicAppContext();
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);

  const openWork = useCallback(() => {
    trackWork(agencySlug, { name: work.name, slug: work.slug });
    setOpen(true);
  }, [work.name, work.slug]);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <WorkCard work={work} onClick={openWork} />
      {open ? (
        <WorkDetailModal
          agencyName={agencyName}
          agencySlug={agencySlug}
          agencyBrandingPlan={agency?.branding_plan || ''}
          agencyLogoUrl={agencyLogoUrl}
          open={open}
          onClose={closeDialog}
          workSlug={work.slug}
          workSlugs={agencyWorkSlugs}
          works={agencyWorks}
          index={currentIndex}
          disableCache={disableCache}
        />
      ) : null}
    </>
  );
};
