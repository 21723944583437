import { GetAgencyCompetitors } from '_backend/queries/get-agency-competitors/get-agency-competitors.types';
import { cachePrefixAgencyCompetitors } from '_backend/queries/get-agency-competitors/version';
import { getQueryApiBaseUrl } from '_config/sl-query-api.config';
import { cqsQueryFetch } from '_core/api/cqs/cqs-query-fetch';

export type Competitors = GetAgencyCompetitors['competitors'];

export const fetchCompetitors = async (params: {
  agencySlug: string;
  locale: string;
  disableCache?: boolean;
  /** Query api baseUrl without ending slash, ie: http://localhost:3000 */
  apiBaseUrl?: string;
}): Promise<Competitors> => {
  const { agencySlug, locale, disableCache = false, apiBaseUrl = getQueryApiBaseUrl() } = params;
  const url = `${apiBaseUrl}/api/query/agency-competitors/${agencySlug}`;

  const data = await cqsQueryFetch<GetAgencyCompetitors, { locale: string }>(
    url,
    { locale },
    {
      cacheVersion: cachePrefixAgencyCompetitors,
      disableCache,
    },
  );

  return data.competitors;
};
