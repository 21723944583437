import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';
import { AddToShortListButtonProps } from '_components/Comparator/AddToShortlistButton';
import { ComparedAgency } from '_components/Comparator/utils';

import { SecondaryCTA } from '../../secondary-cta/secondary-cta';

const AddToShortListButton = dynamic<AddToShortListButtonProps>(
  () => import('_components/Comparator/AddToShortlistButton').then((mod) => mod.AddToShortListButton),
  { ssr: false },
);

const defaultSquareImage = '/_img/default/default-image-landscape.svg';

type Props = {
  agencySlug: string;
  agency: GetAgencyProfile | undefined;
  comparedAgencies: ComparedAgency[];
  setComparedAgencies: (agencies: ComparedAgency[]) => void;
  certified?: boolean;
};

export const CallToActions: FC<Props> = (props) => {
  const { agencySlug, agency, comparedAgencies, setComparedAgencies, certified } = props;
  const { t } = useTranslation(['agency']);
  const logo = agency?.medias.logo?.url ?? defaultSquareImage;
  const name = agency?.name as string;

  return (
    <>
      <DirectBriefingButton
        page="agency"
        className="mb-16 mt-8"
        cta="about-panel"
        truncate={false}
        agencySlug={agencySlug}
        size="md"
        buttonStyle="primary"
        buttonVariant="raised"
        label={<Trans i18nKey={'agency:profile.about.contactCta'}>Contact {{ agencyName: name }}</Trans>}
      />
      <SecondaryCTA
        agencyWebsite={agency?.website_url as string}
        brandingModule={agency?.branding_plan as string}
        agencySlug={agencySlug}
      />
      <AddToShortListButton
        page="profile"
        className="mt-16"
        agency={{ logo, slug: agencySlug, name }}
        showName={false}
        searchContext={undefined}
        comparing={comparedAgencies.some((compared) => agency?.slug === compared.slug)}
        onChange={setComparedAgencies}
        comparedAgencies={comparedAgencies}
      />
    </>
  );
};
