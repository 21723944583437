import { Office } from '_backend/queries/get-agency-offices/get-agency-offices.types';

const findFirstOfficeIndex = (offices: Office[] | undefined, office: Office) => {
  const index = offices?.findIndex((el) => el.slug === office.slug);
  return index !== -1 ? index : null;
};

const hasFreeBrandingModule = (brandingModule: string | null): boolean => ['free', null].includes(brandingModule);

export { findFirstOfficeIndex, hasFreeBrandingModule };
