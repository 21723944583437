import { ProfileLanguage } from '_backend/queries/get-agency-profile/get-agency-profile.types';

export const LANGUAGES_MIN_LENGTH = 5;

const smallCode = (longCode: string) => longCode.split('-')[0];

const firstIndexOfCode = (languages: ProfileLanguage[], code: string) => {
  return languages.findIndex(({ languageCode: lc }) => smallCode(lc) === smallCode(code));
};

// When an agency has 2 similar languages with different regionalism, like 'Dutch' and 'Dutch, Belgium'
// we need to filter them and refer only to Dutch as a whole, otherwise we get something like:
// 'French, Dutch, Belgium, Dutch, English" which is weird.
const filterDistinctLanguages = (languages: ProfileLanguage[]): string[] => {
  return (
    languages
      // We taken only the first occurence of the 2 letters code. Eg. if there is 'nl' and 'nl-BE'
      // we will simplify to 'nl' and take the one having the smallest index.
      .filter(({ languageCode }, index) => firstIndexOfCode(languages, languageCode) === index)
      // We then return the name without the region listed after the coma. Eg. 'Dutch, Belgium'
      // will return as 'Dutch'.
      .map(({ name }) => name.split(',')[0])
  );
};

export const getLanguages = (languages: ProfileLanguage[], isToggledLanguages: boolean) => {
  return languages.length <= LANGUAGES_MIN_LENGTH || isToggledLanguages
    ? filterDistinctLanguages(languages).join(', ')
    : filterDistinctLanguages(languages).slice(0, LANGUAGES_MIN_LENGTH).join(', ') + '...';
};
