import { useConfig } from '@sortlist-frontend/config';
import { ImageSourceHelper } from '@sortlist-frontend/media';

const { publicEnv } = useConfig();
const { s3Bucket: s3BucketUrl } = publicEnv;

const facebookIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/facebook.svg`);
const dribbbleIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/dribbble.svg`);
const vimeoIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/vimeo.svg`);
const youtubeIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/youtube.svg`);
const linkedIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/linkedin.svg`);
const xIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/x.svg`);
const behanceIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/behance.svg`);
const instagramIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/instagram.svg`);
const pinterestIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/pinterest.svg`);
const googlePlusIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/google-plus.svg`);

export type SocialMediaIconsListKey = keyof typeof socialMediaIconsList;

export const socialMediaIconsList = {
  facebook: facebookIcon,
  dribbble: dribbbleIcon,
  vimeo: vimeoIcon,
  youtube: youtubeIcon,
  linkedin: linkedIcon,
  x: xIcon,
  behance: behanceIcon,
  instagram: instagramIcon,
  pinterest: pinterestIcon,
  google_plus: googlePlusIcon,
} as const;

export const isValidSocialMediaKey = (v: string): v is SocialMediaIconsListKey => {
  return (v as string) in socialMediaIconsList;
};
