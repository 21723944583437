import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

// Remove position when merging new position classes from other branch into the DS.
export const AboutSectionStickyBox = styled.div`
  position: sticky;
  top: 10rem;
`;

export const Flag = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const FlagsWrapper = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    position: absolute;
    top: 0;
    right: 1rem;
  }
`;
