import { Trans } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { SectionsDetails } from '_features/agency/profile/components';
import * as S from '_features/agency/profile/sections/services/services.styles';
import { Section } from '_features/agency/profile/utils';

type Props = {
  serviceName: string;
  clients: GetAgencyServices['services'][0]['clients']['data'];
  className?: string;
  agencySlug?: string;
  handleActivePanel: (value: Section | undefined) => void;
};

export const ServiceClients: FC<Props> = (props) => {
  const { className, clients, serviceName, handleActivePanel, agencySlug } = { ...props };
  const maxNumberOfCardsDisplayed = 4;

  return (
    <section className={`${className} p-16 layout-column`}>
      <header className="p-8">
        <Trans i18nKey={'agency:profile.services.clientsTitle'} count={clients.length}>
          <b>Clients</b> in {{ serviceName: serviceName }} ({{ count: clients.length }})
        </Trans>
      </header>
      <div className="flex layout-row p-8">
        <div className="flex layout-row layout-wrap">
          {clients.slice(0, maxNumberOfCardsDisplayed).map((client, index) => (
            <div key={`${client.name}-${index}`} className="pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20 layout-row">
              <div className="shadow-2 flex p-8 rounded-sm bg-neutral-100 layout-column">
                <span className="small bold mb-4">{client.name}</span>
                <span className="small italic text-secondary-500">
                  {client.sector} {client.market_size != null && `| ${client.market_size}`}
                </span>
              </div>
            </div>
          ))}
          {clients.length > maxNumberOfCardsDisplayed && (
            <div className="pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20 layout-row layout-align-start-stretch">
              <S.ItemExpand
                buttonStyle="secondary"
                size="lg"
                className="layout-fill bg-secondary-200 text-secondary-500"
                onClick={() => handleActivePanel(SectionsDetails?.find((section) => section.id === 'portfolio'))}
                label={`+${clients.length - maxNumberOfCardsDisplayed}`}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
