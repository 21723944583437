import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const TeamPicture = styled.div`
  img {
    height: 25rem;
    object-fit: cover;

    @media only screen and (max-width: ${BREAKPOINTS.xs}) {
      height: auto;
    }
  }
`;
