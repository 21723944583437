import { useConfig } from '@sortlist-frontend/config';
import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import Image from 'next/image';
import { FC } from 'react';

type Props = {
  t: TFunction;
  className?: string;
  absolute?: boolean;
};

export const CertifiedFlag: FC<Props> = ({ className, absolute, t }) => {
  const { publicEnv } = useConfig();
  const { s3Bucket: s3BucketUrl } = publicEnv;

  return (
    <Tooltip className="text-center" arrow={true} placement="top" title={t('common:flags.certifiedTooltip_v2')}>
      <Image
        className={`${className ?? ''} ${absolute ? 'flag-absolute' : ''} flag`}
        src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/flags/certified-flag-primary.svg`)}
        alt="certified-flag"
        height="28"
        width="22"
      />
    </Tooltip>
  );
};
