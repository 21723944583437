import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const ArrowForwardButton = styled.div`
  top: 50%;
  right: 2.75rem;
  transform: translateY(-1.25rem);

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    right: 1rem;
  }
`;

export const ArrowBackwardButton = styled.div`
  top: 50%;
  left: 2.75rem;
  transform: translateY(-1.25rem);

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    left: 1rem;
  }
`;

export const AgencyCard = styled.div`
  min-height: 18.75rem; // 300px
`;
