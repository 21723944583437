/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { FC, useCallback, useState } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { WorkDetailModal } from '_components/common/WorkDetailModal';
import { trackWork } from '_components/common/WorkDetailModal/tracker';
import { usePublicAppContext } from '_core/context/public-app-context';
import { onImageError } from '_core/media/get-media.utils';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import * as S from '_features/agency/profile/sections/services/services.styles';

type Props = {
  agencyName: string;
  agencySlug: string;
  agencyLogoUrl: string;
  index: number;
  work: GetAgencyServices['services'][0]['works']['data'][0];
  workSlugs: string[];
  serviceName: string;
  disableCache?: boolean;
};

export const ServiceWork: FC<Props> = (props) => {
  const { agencyName, agencySlug, disableCache, index, agencyLogoUrl, workSlugs, work, serviceName } = {
    ...props,
  };
  const { locale } = usePublicAppContext();
  const [open, setOpen] = useState(false);
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);

  const openWork = useCallback(() => {
    trackWork(agencySlug, { name: work?.name, slug: work?.slug });
    setOpen(true);
  }, [work?.name, work?.slug]);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <S.ServiceWorkCard className={'pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20 layout-row'}>
        <img
          suppressHydrationWarning // we need this because content is changing from server to client
          loading={'lazy'}
          src={ImageSourceHelper.fromUrl(work.attachment.url, { width: 400 })}
          alt={`${work.name} - ${serviceName}`}
          onError={onImageError}
          onClick={openWork}
          className={'cursor-pointer bg-secondary-200 flex shadow-2 rounded-sm width-100'}
        />
      </S.ServiceWorkCard>
      <WorkDetailModal
        agencyName={agencyName}
        agencySlug={agencySlug}
        agencyBrandingPlan={agency?.branding_plan || ''}
        agencyLogoUrl={agencyLogoUrl}
        open={open}
        onClose={closeDialog}
        workSlug={work.slug}
        workSlugs={workSlugs}
        // @TODO: update query to get full work details in order to be able to display & open similar works section?
        // works={works}
        index={index}
        disableCache={disableCache}
      />
    </>
  );
};
