import { css, Global } from '@emotion/react';
import { ArrowForwardRounded } from '@mui/icons-material';
import {
  Divider,
  Menu as MuiMenu,
  type MenuItemProps as MuiMenuItemProps,
  type MenuProps as MuiMenuProps,
} from '@mui/material';
import clsx from 'clsx';
import { CSSProperties, FC, forwardRef, Fragment, MouseEvent, ReactElement, ReactNode } from 'react';

import { RADIUSES } from '../../variables';
import { Button } from '../Button';
import * as S from './styles';

type MenuItemButtonProps = {
  id: string;
  label?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  groupItem?: boolean;
  children?: ReactNode;
};

export const MenuItemButton = forwardRef<HTMLButtonElement, MenuItemButtonProps>((props, ref) => {
  const { onClick, children, className, ...restProps } = { ...props };
  return (
    <Button
      ref={ref}
      buttonVariant="default"
      buttonStyle="secondary"
      className={clsx(className, 'text-left bg-none border-none width-100 p-0 m-0')}
      onClick={onClick}
      {...restProps}>
      {children}
    </Button>
  );
});

type InnerMenuItemProps = {
  label?: string;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  subMenu?: boolean;
};

const InnerMenuItemIconCssStyle: CSSProperties = { fontSize: 14 };

export const InnerMenuItem = forwardRef<HTMLDivElement, InnerMenuItemProps>((props, ref) => {
  const { iconLeft, iconRight, label, subMenu } = { ...props };
  return (
    <div ref={ref} className="layout-row layout-align-start-center flex">
      {iconLeft}
      <span className={clsx({ 'ml-8': iconLeft }, { 'mr-8': iconRight || subMenu }, 'small flex sans-serif')}>
        {label}
      </span>
      {!iconRight && !subMenu ? (
        iconRight
      ) : (
        <ArrowForwardRounded className="text-secondary-700" style={InnerMenuItemIconCssStyle} />
      )}
    </div>
  );
});

export type MenuItemProps = {
  label?: string;
  border?: boolean;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  childrenItems?: ReactElement;
  onClick?: () => void;
  subMenu?: boolean;
  backgroundColor?: string;
} & MuiMenuItemProps;

const defaultMenuItemProps = {
  groupItem: false,
  border: false,
};

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>((props, MenuItemRef) => {
  const { children, iconLeft, iconRight, label, subMenu, border, onClick, color, backgroundColor, ...restProps } = {
    ...defaultMenuItemProps,
    ...props,
  };

  return (
    <Fragment>
      {children ? (
        <S.MenuItem onClick={onClick} ref={MenuItemRef} color={color} backgroundcolor={backgroundColor} {...restProps}>
          {children}
        </S.MenuItem>
      ) : (
        <Fragment>
          <S.MenuItem
            onClick={onClick}
            ref={MenuItemRef}
            color={color}
            backgroundcolor={backgroundColor}
            {...restProps}>
            <InnerMenuItem iconLeft={iconLeft} iconRight={iconRight} subMenu={subMenu} label={label} />
          </S.MenuItem>
          {border ? <Divider /> : null}
        </Fragment>
      )}
    </Fragment>
  );
});

export const Menu: FC<MuiMenuProps> = (props) => {
  const { children, ...restProps } = { ...props };
  return (
    <Fragment>
      <Global
        styles={css`
          .MuiMenu-paper {
            border-radius: ${RADIUSES.sm} !important;
            box-shadow:
              0 8px 16px rgb(144 144 144 / 15%),
              0 10px 30px rgb(144 144 144 / 12%) !important;
          }
        `}
      />
      <MuiMenu {...restProps}>{children}</MuiMenu>
    </Fragment>
  );
};
