import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const View = styled.div`
  min-height: 100%;
  background: ${COLORS.SECONDARY_100};
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    background: ${COLORS.NEUTRAL_100};
  }
`;

export const Shape = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    border-radius: 0;
    box-shadow: none;
  }
`;
