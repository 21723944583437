import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { GetWork } from '_backend/queries/get-work/get-work.types';
import { cachePrefixWork } from '_backend/queries/get-work/version';
import { getQueryApiBaseUrl } from '_config/sl-query-api.config';
import { cqsQueryFetch } from '_core/api/cqs/cqs-query-fetch';

export type PortfolioWorks = GetAgencyPortfolio['works'];
export type PortfolioWork = GetWork;

export const fetchWork = async (params: {
  workSlug: string;
  locale: string;
  disableCache?: boolean;
  apiBaseUrl?: string;
}): Promise<GetWork> => {
  const { workSlug, locale, disableCache = false, apiBaseUrl = getQueryApiBaseUrl() } = params;
  const url = `${apiBaseUrl}/api/query/work/${workSlug}`;

  return cqsQueryFetch<GetWork, { locale: string }>(
    url,
    {
      locale,
    },
    {
      disableCache,
      cacheVersion: cachePrefixWork,
    },
  );
};
