import styled from '@emotion/styled';
import { BREAKPOINTS, Dialog } from '@sortlist-frontend/design-system';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthMd {
    max-width: 66rem;
  }
  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    > .MuiDialog-container > .MuiDialog-paper {
      border-radius: 1rem;
      margin: 2rem auto 2rem auto;
    }
  }
`;
