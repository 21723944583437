import { Avatar } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import type { FC } from 'react';

import { useSharedComponentsContext } from '../../utils';
import { Review } from '../types';

type Props = {
  review: Review;
  className?: string;
};

export const User: FC<Props> = (props) => {
  const { Trans } = useSharedComponentsContext();
  const { review, className } = { ...props };

  const { userJobTitle, userCompanyName, userPictureUrl, userFullName } = review;

  return (
    <div data-testid="review-card-user" className={clsx(className, 'sortlist-review_user_container')}>
      <Avatar
        className="mb-8"
        src={userPictureUrl ?? undefined}
        alt={userFullName ?? undefined}
        size={'md'}
        avatarUiName={userFullName ?? undefined}
        imgLoading="lazy"
      />
      <div className="my-8 small layout-column text-break-word">
        {userFullName && (
          <span data-testid="review-card-user-full-name" className="bold text-capitalize">
            {userFullName}
          </span>
        )}
        {userJobTitle && userCompanyName && (
          <span data-testid="review-card-user-job-company">
            <Trans i18nKey={'base:reviewCard.jobAtCompany'}>
              {{ jobTitle: userJobTitle }} at {{ companyName: userCompanyName }}
            </Trans>
          </span>
        )}
        {userJobTitle && !userCompanyName && (
          <span data-testid="review-card-user-job-no-company" className="text-capitalize">
            {userJobTitle}
          </span>
        )}
        {!userJobTitle && userCompanyName && (
          <span data-testid="review-card-user-company-no-job" className="text-capitalize">
            {userCompanyName}
          </span>
        )}
      </div>
    </div>
  );
};
