import { AssignmentTwoTone } from '@mui/icons-material';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useState } from 'react';

import { CtaBox } from '_components/common/CtaBox';
import { ToggleButton } from '_components/common/ToggleButton';
import { usePublicAppContext } from '_core/context/public-app-context';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';
import { PortfolioWorksList } from '_features/agency/profile/sections/portfolio/components/portfolio-works.list';

import { useGetAgencyPortfolio, useGetAgencyProfile } from '../../../../../core/repos/agency-profile.repo';

export type PortfolioPanelProps = {
  children?: never;
  agencyLogoUrl: string;
  className?: string;
  disableCache: boolean;
};

export const PortfolioPanel: FC<PortfolioPanelProps> = (props) => {
  const { className, agencyLogoUrl, disableCache } = props;
  const [toggledAllWorks, setToggledAllWorks] = useState(false);
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const { data } = useGetAgencyPortfolio(agencySlug, locale as string);
  const { works } = data || {};
  const { name } = agency || {};
  const worksCount = Array.isArray(works) ? works.length : 0;

  return (
    <SectionBox
      id="portfolio"
      className={clsx(className, 'py-gt-xs-96 py-xs-64')}
      title={t('agency:profile.portfolio.title')}
      subtitle={
        <Trans i18nKey={'agency:profile.portfolio.subtitle'} count={worksCount}>
          <b>
            <>{{ count: worksCount }} works</>
          </b>
          uploaded by {{ name }}
        </Trans>
      }>
      <>
        <div className="px-24 px-gt-sm-56">
          <div className="layout-row layout-wrap">
            <PortfolioWorksList
              maxVisible={toggledAllWorks && works ? works.length : 6}
              works={works as []}
              agencySlug={agencySlug}
              agencyName={name as string}
              agencyLogoUrl={agencyLogoUrl}
              disableCache={disableCache}
            />
          </div>
          {worksCount > 6 ? (
            <div className="layout-row">
              <ToggleButton
                className="flex"
                handleToggled={() => setToggledAllWorks(!toggledAllWorks)}
                seeMoreBtnLabel={
                  <Trans i18nKey={'agency:profile.portfolio.seeMoreWorks'} count={worksCount}>
                    See all other works ({{ count: worksCount - 6 }})
                  </Trans>
                }
                seeLessBtnLabel={t('agency:profile.portfolio.seeLessWorks')}
                toggled={toggledAllWorks}
              />
            </div>
          ) : null}
        </div>
        <footer className="p-32 layout-row layout-align-center-center hide-xs">
          <CtaBox
            title={t('agency:profile.portfolio.cta.title')}
            subtitle={t('agency:profile.portfolio.cta.subtitle', { name })}
            btnLabel={t('agency:profile.portfolio.cta.btnLabel')}
            icon={<AssignmentTwoTone />}
            element="portfolio-panel"
            agencySlug={agencySlug}
          />
        </footer>
      </>
    </SectionBox>
  );
};
