import { HttpException } from './http-exception';
import { getHttpExceptionByStatus, HttpExceptionClass } from './supported-http-exceptions';

export class HttpExceptionFactory {
  public static fromStatus(status: number, message: string, url?: string, defaultStatus = 500) {
    let httpExceptionCls: HttpExceptionClass;
    try {
      httpExceptionCls = getHttpExceptionByStatus(status);
    } catch (e) {
      httpExceptionCls = HttpException;
    }
    return new httpExceptionCls({ message, status: httpExceptionCls?.STATUS ?? defaultStatus, url });
  }
}
