import styled from '@emotion/styled';
import { BREAKPOINTS, Button } from '@sortlist-frontend/design-system';

export const ItemExpand = styled(Button)`
  border-radius: 0.375rem !important;
  display: flex !important;
  min-height: 4rem;
`;

export const ExpertiseIcon = styled.div`
  width: 2rem;
  height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
`;

export const Shape = styled.ul`
  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
  }
`;

export const ServiceWorkCard = styled.div`
  min-width: 6rem;
  max-height: 7rem !important;
  > img {
    object-fit: cover;
  }
`;

type PreviewButtonProps = {
  hasinfo?: number;
};

export const PreviewButton = styled(Button)`
  ${(props: PreviewButtonProps) => `
    cursor: ${props.hasinfo ? 'pointer' : 'initial'};
  `}
`;
