import { LanguageTwoTone } from '@mui/icons-material';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';

import { getLanguages, LANGUAGES_MIN_LENGTH } from './utils';

export const Languages = () => {
  const [isToggledLanguages, setToggledLanguages] = useState(false);
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const { languages } = agency ?? { languages: [] };

  return (
    <div className="flex-50 flex-xs-100 layout-row layout-align-start-center">
      <LanguageTwoTone style={{ fontSize: 16 }} className="m-8 text-secondary-500" />
      <span className="m-8 small text-break-word">
        <Trans i18nKey={'agency:profile.about.details.speakLanguages'}>
          Speaks
          <b className="text-capitalize">{{ languages: getLanguages(languages, isToggledLanguages) }}</b>
        </Trans>
        {languages.length > LANGUAGES_MIN_LENGTH && !isToggledLanguages ? (
          <>
            &nbsp;
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <a className="cursor-pointer underline text-secondary-900" onClick={() => setToggledLanguages(true)}>
              {t('common:clampLines.seeMore')}
            </a>
          </>
        ) : null}
      </span>
    </div>
  );
};
