import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';
import Image from 'next/image';

export const HeroSectionHeader = styled.header`
  height: 20rem;
  margin-bottom: -2.15rem;

  img {
    object-fit: cover;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    border-radius: 0;
  }
`;

export const HeroSectionAnchorBar = styled.main`
  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    top: 0px;
    z-index: 98;
  }
`;

export const HeroSectionLogo = styled(Image)`
  z-index: 1;
  transition: all 0.2s ease-in-out;
  border: 2px solid ${COLORS.NEUTRAL_100};

  object-fit: contain;

  @media only screen and (min-width: ${BREAKPOINTS.xs}) and (max-width: ${BREAKPOINTS.sm}) {
    position: absolute !important;
    top: -8rem;
    left: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    top: -3.5rem;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute !important;
  }

  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    &.shrinked {
      opacity: 0;
      width: 0;
      height: 0;
      transform: scale(0);
      transition: all 0.15s ease-in-out;
    }
  }
`;
